export default {
  borderWidths: {
    "0": "0",
    "2": "2px",
    "4": "4px",
    "8": "8px",
    px: "1px",
  },
  breakpoints: ["475px", "768px", "1024px", "1280px"],
  cards: {
    caseStudy: {
      color: "white",
      padding: "6",
      boxShadow: "lg",
      cursor: "pointer",
    },
    blog: {
      display: "flex",
      flexDirection: "column",
    },
  },
  colors: {
    transparent: "transparent",
    white: "#fff",
    blue: {
      xlight: "#EEF4F9",
      light: "#5495CA",
      mid: "#438BF7",
      dark: "#3A4B79",
      xdark: "#1E2F46",
      xxdark: "#0B1A3E",
    },
    red: {
      light: "#DD232E",
      dark: "#C01E28",
    },
    grey: {
      xlight: "#F8F8F9",
      light: "#D0D2D5",
      mid: "#BBBDC0",
      dark: "#5A5773",
    },
    text: "#111111",
    background: "#fff",
    primary: "#C01E28",
    secondary: "#79ACD4",
  },
  fonts: {
    primary: "Greycliff, sans-serif",
    heading: "inherit",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 40, 48, 64, 72, 96],
  fontWeights: {
    regular: 400,
    medium: 500,
    bold: 700,
    heavy: 900,
    heading: 900,
  },
  grids: {
    placeCenter: {
      placeContent: "center",
    },
  },
  links: {
    nav: {
      fontWeight: "medium",
      textDecoration: "none",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
        color: "red.dark",
      },
    },
    cta: {
      variant: "nav",
      fontWeight: "bold",
      color: "blue.mid",
    },
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
  lineHeights: {
    none: "1",
    tight: "1.25",
    snug: "1.375",
    normal: "1.5",
    relaxed: "1.625",
    loose: "2",
    body: "1.625",
    heading: "1.25",
  },
  shadows: {
    xs: "0 0 0 1px rgba(0, 0, 0, 0.05)",
    sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    default: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    lg:
      "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    xl:
      "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
    outline: "0 0 0 3px rgba(66, 153, 225, 0.5)",
    none: "none",
    text: "0 0 8px #0B1A3D40",
  },
  space: [0, 4, 8, 16, 24, 32, 40, 48, 64, 128, 256, 512],
  radii: {
    none: "0",
    sm: "0.125rem",
    default: "0.25rem",
    md: "0.375rem",
    lg: "0.5rem",
    full: "9999px",
  },
  sizes: {
    "0": "0",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "8": "2rem",
    "10": "2.5rem",
    "12": "3rem",
    "16": "4rem",
    "20": "5rem",
    "24": "6rem",
    "32": "8rem",
    "40": "10rem",
    "48": "12rem",
    "56": "14rem",
    "64": "16rem",
    px: "1px",
    xs: "20rem",
    sm: "24rem",
    md: "28rem",
    lg: "32rem",
    xl: "36rem",
    "2xl": "42rem",
    "3xl": "48rem",
    "4xl": "56rem",
    "5xl": "64rem",
    "6xl": "72rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    "1/12": "8.333333%",
    "2/12": "16.666667%",
    "3/12": "25%",
    "4/12": "33.333333%",
    "5/12": "41.666667%",
    "6/12": "50%",
    "7/12": "58.333333%",
    "8/12": "66.666667%",
    "9/12": "75%",
    "10/12": "83.333333%",
    "11/12": "91.666667%",
    full: "100%",
    screenHeight: "100vh",
    screenWidth: "100vw",
    container: 1280,
  },
  text: {
    heading: {
      fontSize: [7, null, 9],
      fontWeight: "heading",
      lineHeight: "none",
      two: {
        fontSize: [5, null, 8],
        lineHeight: "none",
      },
      three: {
        fontSize: 5,
        lineHeight: "none",
      },
    },
    body: {
      sm: {
        variant: "text.body.normal",
        fontSize: "1",
      },
      normal: {
        fontFamily: "primary",
        fontWeight: "regular",
        fontSize: 2,
        lineHeight: "normal",
      },
      mid: {
        variant: "text.body.normal",
        fontSize: [2, null, 3],
      },
      lg: {
        variant: "text.body.normal",
        fontSize: [null, null, 3, null, 4],
      },
    },
    blog: {
      card: {
        title: {
          fontSize: "4",
          color: "blue.xdark",
        },
        subtitle: {
          color: "grey.dark",
          fontSize: "2",
          fontWeight: "bold",
        },
        excerpt: {
          fontSize: ["1", null, "2"],
          lineHeight: "normal",
        },
      },
      post: {
        title: {
          variant: "text.blog.card.title",
          fontSize: "6",
        },
        subtitle: {
          variant: "text.blog.card.subtitle",
          fontSize: "4",
        },
      },
    },
    subhead: {
      fontSize: [3, null, 5],
      fontWeight: "medium",
      lineHeight: "tight",
    },
    title: {
      fontSize: 6,
      fontWeight: "bold",
      lineHeight: "none",
    },
    smallCaps: {
      fontSize: 7,
      fontWeight: "heading",
      lineHeight: "none",
      textTransform: "uppercase",
      letterSpacing: "widest",
    },
    descTitle: {
      fontSize: 3,
      lineHeight: "none",
    },
    description: {
      fontSize: 2,
      fontWeight: "medium",
      lineHeight: "normal",
    },
  },
  buttons: {
    outline: {
      fontWeight: "medium",
      color: "white",
      cursor: "pointer",
      backgroundColor: "transparent",
      border: "1px solid",
      borderColor: "blue.light",
      fontFamily: "primary",
      lineHeight: "none",
      transition: "all 300ms ease-in-out",
      "&:hover": {
        backgroundColor: "blue.light",
        color: "white",
      },
      "&:focus": {
        outline: "none",
        borderColor: "blue.dark",
      },
    },
    outlineLight: {
      variant: "buttons.outline",
      border: "2px solid",
      color: "blue.xdark",
      fontWeight: "bold",
    },
    white: {
      variant: "buttons.outline",
      fontWeight: "bold",
      backgroundColor: "white",
      border: "none",
      color: "red.dark",
    },
  },
  divider: {
    thick: {
      backgroundColor: "blue.light",
      height: "2",
      m: "0",
      marginBlockStart: "0",
      marginBlockEnd: "0",
      border: "none",
    },
    thin: {
      variant: "divider.thick",
      height: "1px",
      opacity: "0.25",
    },
    light: {
      marginBlockStart: "0",
      marginBlockEnd: "0",
      color: "grey.light",
      height: "2px",
    },
  },
  styles: {
    root: {
      fontFamily: "primary",
      overflowX: "hidden",
    },
    p: {
      mt: "3",
    },
    hr: {
      variant: "divider.thick",
    },
  },
};
